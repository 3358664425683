<template>
  <b-sidebar
      id="add-new-sidebar"
      :visible="isAddNewSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @hidden="resetForm"
      @change="(val) => $emit('update:is-add-new-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ titulo }}
        </h5>

        <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
        >

          <label> Tipo de cliente</label>
          <div class="d-flex mb-2">
            <b-form-radio
                v-model="tipoCliente"
                class="mr-2"
                name="some-radios"
                value="PJ"
            >
              PJ
            </b-form-radio>
            <b-form-radio
                v-model="tipoCliente"
                name="some-radios"
                value="PF"
            >
              PF
            </b-form-radio>

          </div>

          <!-- Razão Social -->

          <validation-provider
              v-if="tipoCliente === 'PJ'"
              #default="validationContext"
              name="Razão Social"
          >
            <b-form-group
                label="Razão Social"
                label-for="razao-social"
            >
              <b-form-input
                  v-model="userData.razao_social"
                  name="razao_social"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Informe um nome da empresa"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
              v-if="tipoCliente === 'PF'"
              #default="validationContext"
              name="Nome"
          >
            <b-form-group
                label="Nome"
                label-for="nome"
            >
              <b-form-input
                  v-model="userData.razao_social"
                  name="razao_social"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Informe o nome do cliente"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- CNPJ -->
          <validation-provider
              v-if="tipoCliente === 'PJ'"
              #default="validationContext"
              name="CNPJ"
              rules=""
          >
            <b-form-group
                label="CNPJ"
                label-for="cnpj"
            >
              <b-form-input
                  id="cnpj"
                  v-model="userData.cnpj"
                  v-mask="'##.###.###/####-##'"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="00.000.000/0000-00"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- CPF -->
          <validation-provider
              v-if="tipoCliente === 'PF'"
              #default="validationContext"
              name="CPF"
              rules=""
          >
            <b-form-group
                label="CPF"
                label-for="cpf"
            >
              <b-form-input
                  id="cpf"
                  v-model="userData.cpf"
                  v-mask="'###.###.###-##'"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="000.000.000-00"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email - rules="required|email"  -->
          <validation-provider
              #default="validationContext"
              name="E-mail"
              rules="email"
          >
            <b-form-group
                label="E-mail"
                label-for="email"
            >
              <b-form-input
                  id="email"
                  v-model="userData.email"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Informe um e-mail"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Contato Resposável -->
          <validation-provider
              #default="validationContext"
              name="Contato Resposável"
          >
            <b-form-group
                label="Contato Responsável"
                label-for="contato-responsavel"
            >
              <b-form-input
                  id="contato-responsavel"
                  v-model="userData.contato_responsavel"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Informe a pessoa referência na empresa"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Telefone -->
          <validation-provider
              #default="validationContext"
              name="Telefone"
          >
            <b-form-group
                label="Telefone"
                label-for="telefone"
            >
              <b-form-input
                  id="contact"
                  ref="telefoneInput"
                  v-model="userData.telefone"
                  v-mask="'(##) # ####-####'"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Ex: (00) 0 0000-0000"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Logradouro -->
          <validation-provider
              #default="validationContext"
              name="Logradouro"
          >
            <b-form-group
                label="Logradouro"
                label-for="Logradouro"
            >
              <b-form-input
                  id="logradouro"
                  v-model="userData.endereco.logradouro"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Ex: Rua 7 de Julho"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Logradouro -->
          <validation-provider
              #default="validationContext"
              name="Número"
          >
            <b-form-group
                label="Número"
                label-for="numero"
            >
              <b-form-input
                  id="numero"
                  v-model="userData.endereco.num_endereco"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Ex: 500"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Complemento -->
          <validation-provider
              #default="validationContext"
              name="Complemento"
          >
            <b-form-group
                label="Complemento (Opcional)"
                label-for="Complemento"
            >
              <b-form-input
                  id="contact"
                  v-model="userData.endereco.complemento"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Ex: Apto 500"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- CEP -->
          <validation-provider
              #default="validationContext"
              name="CEP"
          >
            <b-form-group
                label="CEP"
                label-for="cep"
            >
              <b-form-input
                  id="cep"
                  ref="telefoneInput"
                  v-model="userData.endereco.cep"
                  v-mask="'##.###-####'"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Ex: 00.000-0000"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Estado -->
          <validation-provider
              #default="validationContext"
              name="Estado"
          >
            <b-form-group
                label="Estado"
                label-for="Estado"
            >
              <b-form-input
                  id="estado"
                  v-model="userData.endereco.estado"
                  :maxlength="2"
                  pattern="[A-Za-z]{2}"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Ex: SC"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Cidade -->
          <validation-provider
              #default="validationContext"
              name="Rua"
          >
            <b-form-group
                label="Cidade"
                label-for="Cidade"
            >
              <b-form-input
                  id="cidade"
                  v-model="userData.endereco.cidade"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Ex: São José"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Bairro -->
          <validation-provider
              #default="validationContext"
              name="Bairro"
          >
            <b-form-group
                label="Bairro"
                label-for="Bairro"
            >
              <b-form-input
                  id="bairro"
                  v-model="userData.endereco.bairro"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Ex: Centro"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Empresa  -->
          <validation-provider
              #default="validationContext"
              name="Empresa"
          >
            <b-form-group
                label="Empresa"
                label-for="empresas"
                :state="getValidationState(validationContext)"
            >
              <v-select
                  v-model="userData.empresas"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="empresaOptions"
                  label="nome"
                  :reduce="val => val.uuid"
                  :clearable="false"
                  input-id="user-empresa"
                  placeholder="Escolha uma empresa"
                  multiple
              >
                <template v-slot:no-options>
                  Nenhuma opção encontrada
                </template>
              </v-select>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Logo  -->

          <validation-provider
              #default="validationContext"
              name="Logo"
          >
            <b-form-file
                v-model="userData.logo"
                label="Logo"
                label-for="logo"
                :state="getValidationState(validationContext)"
                browse-text="Procurar"
                placeholder="Escolha um arquivo"
                drop-placeholder="Escolha um arquivo"
            />

            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="mr-2"
                type="submit"
            >
              Salvar
            </b-button>
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
            >
              Cancelar
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { alphaNum, email, required } from '@validations'
import {
  BButton,
  BForm,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadio,
  BSidebar,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import vSelect from 'vue-select'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormFile,
    vSelect,
    BFormRadio,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewSidebarActive',
    event: 'update:is-add-new-sidebar-active',
  },
  props: {
    isAddNewSidebarActive: {
      type: Boolean,
      required: true,
    },
    titulo: {
      type: String,
      required: true,
    },

    empresaOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,

    }
  },
  setup(props, { emit }) {
    const blankUserData = {
      razao_social: '',
      cnpj: '',
      email: '',
      telefone: null,
      empresa: '',

      endereco: {
        logradouro: '',
        num_endereco: '',
        complemento: '',
        bairro: '',
        cep: '',
        cidade: '',
        estado: '',
      },

    }
    const isAddNewSidebarActive = ref(false)
    // Use toast
    const toast = useToast()

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {
      store.dispatch('app-leads/store', userData.value)
          .then(respo => {
            emit('refetch-data')
            emit('update:is-add-new-sidebar-active', false)
            toast({
              component: ToastificationContent,
              props: {
                title: 'Lead adicionado com sucesso',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            if (error.response && error.response.status === 422) {
              const listaErros = error.response.data.errors
              for (const field in listaErros) {
                if (listaErros.hasOwnProperty(field)) {
                  const errorMessages = listaErros[field]
                  errorMessages.forEach(errorMessage => {
                    toast({
                      component: ToastificationContent,
                      props: {
                        title: errorMessage,
                        icon: 'AlertCircleIcon',
                        variant: 'danger',
                      },
                    })
                  })
                }
              }
            }
          })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,
      tipoCliente: 'PJ',
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
